<template>
  <div class="table-responsive">
    <div class="row">
      <div class="col col-6">
        <h5 v-if="feature">
          {{sortedMutations.length }} AA variants in selected
          <span v-if="feature && feature.type === 'pdb'">PDB </span>
           <span v-if="feature && feature.type === 'protein'">protein </span>
          :
        </h5>
      </div>
      <div class="col col-6">
        <div class="float-right" v-if="feature">
          <div class="dropdown">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="align-middle material-icons">save_alt</span>
              <span class="align-middle">&nbsp;Download&nbsp;as</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                v-if="feature && feature.type === 'pdb'"
                _target="blank"
                class="dropdown-item"
                v-on:click="downloadHist()"
                title="Download mutation list for PDB"
              >
                <span class="align-middle material-icons">bar_chart</span>
                <span class="align-middle">Histogram</span>
              </a>
              <a
                _target="blank"
                class="dropdown-item"
                v-on:click="download()"
                title="Download table"
              >
                <span class="align-middle material-icons">grid_on</span>
                <span class="align-middle">TSV</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped table-sm table-hover table-variants" style="width: 100%;">
      <thead>
        <tr>
          <th></th>
          <th>
            <a class="sorting" v-on:click="sortBy('variant')" title="Click to sort">Genome position</a>
          </th>
          <th>
            <a class="sorting" v-on:click="sortBy('refRegion')" title="Click to sort">Gene/protein name</a>
          </th>
          <th>
            <a
              class="sorting"
              v-on:click="sortBy('mutationNumber')"
              title="Click to sort"
            >Missense mutations count</a>
          </th>
          <th>
            <a
              class="sorting"
              v-on:click="sortBy('mutationType')"
              title="Click to sort"
            >Mutation types</a>
          </th>
          <th>
            <a
              class="sorting"
              v-on:click="sortBy('annotationType')"
              title="Click to sort"
            >Annotations</a>
          </th>
          <th>Base change: count</th>
          <th>Protein position: AA change: count</th>
          <th v-if="feature && feature.type === 'pdb'">Residue number in PDB</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="m in sortedMutations" v-bind:key="m.variant" v-bind:class="m.variant">
          <td>
            <button type="button" class="btn btn-primary btn-sm" v-on:click="zoomTo(m.variant)">View</button>
          </td>
          <td>
            {{m.variant}}
           
          </td>
          <td>{{m.refRegion}}</td>
          <td>{{m.mutationNumber}}</td>
          <td>{{m.mutationType}}</td>
          <td style="max-width:300px;">{{m.annotationType && m.annotationType.replace(/_/g, ' ')}}</td>
          <td class="text-break" style="max-width:300px;">{{m.alt}}</td>
          <td class="text-break" style="max-width:300px;">{{m.proteinAminoAcids}}</td>
          <td v-if="feature && feature.type === 'pdb'">{{dna2prot(m.variant)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "VariantsTable",
  props: {
    mutations: Array,
    feature: Object
  },
  data: function() {
    return {
      sortKey: "mutationNumber",
      reverse: true
    };
  },
  computed: {
    sortedMutations: function() {
      let k = this.sortKey;
      let o = this.reverse ? -1 : 1;

      let compare = function(a, b) {
        let res;
        if (k === "variant" || k === "mutationNumber")
          res = parseInt(a[k]) > parseInt(b[k]) ? 1 : -1;
        else res = a[k] > b[k] ? 1 : -1;
        return res * o;
      };

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.mutations.sort(compare);
    }
  },
  methods: {
    zoomTo(pos) {
      this.$parent.zoomTo(pos);
    },
    dna2prot: function(x) {
      if (this.feature && this.feature.type === "pdb")
        return this.$parent.dna2prot(
          x,
          this.feature.start,
          this.feature.end,
          this.feature.properties.alignment_start
        );
      return "";
    },
    sortBy: function(sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;
      this.sortKey = sortKey;
    },
    download: function() {
      if (!this.feature) return;
      var text =
        "#Genome position\tGene name\tCount\tMutation Type\tAnnotation Type\t" +
        "Base change:Virus number\tAA change";

      if (this.feature.type === "pdb") text += "\tResidue number in PDB";
      text += "\n";

      this.sortedMutations.forEach(m => {
        text +=
          m.variant +
          "\t" +
          m.refRegion +
          "\t" +
          m.mutationNumber +
          "\t" +
          m.mutationType +
          "\t" +
          m.annotationType +
          "\t" +
          m.alt +
          "\t" +
          m.proteinAminoAcids;

        if (this.feature.type === "pdb")
          text += "\t" + this.dna2prot(m.variant);

        text += "\n";
      });
      var data = new Blob([text], { type: "text/plain" });
      try {
        window.saveAs(
          data,
          "coronavirus3d-" + this.feature.label + "-mutations.tsv"
        );
      } catch (e) {
        var url = window.URL.createObjectURL(data);
        window.open(url);
      }
    },
    downloadHist: function() {
      if (!this.feature && !this.feature.type === "pdb") return;
      var text = "#Count\tResidue number in PDB\n";

      this.sortedMutations.forEach(m => {
        text += m.mutationNumber + "\t" + this.dna2prot(m.variant) + "\n";
      });

      var data = new Blob([text], { type: "text/plain" });
      try {
        window.saveAs(
          data,
          "coronavirus3d-" + this.feature.label + "-historgam.txt"
        );
      } catch (e) {
        var url = window.URL.createObjectURL(data);
        window.open(url);
      }
    }
  }
};
</script>

<style scoped>
a.sorting {
  font-weight: bolder;
  color: #007bff;
  cursor: pointer;
}
</style>
